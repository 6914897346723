import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import "../styles/bg.css";
import {AdIosaregionSwipetoslide} from "../components/react-slick/ad-iosaregion-swipetoslide";
import title from "../images/sale/xr-page1-title.gif";
import btn2 from "../images/sale/xr-btn2.png";
import g0 from "../images/sale/1.gif";
import g1 from "../images/sale/2.gif";
import g2 from "../images/sale/4.gif";
import g3 from "../images/sale/3.gif";
import g4 from "../images/sale/5.jpg";
import g5 from "../images/sale/6.jpg";
// import g6 from "../images/sale/7.gif";
// import g7 from "../images/sale/8.jpg";
import cookie from "react-cookies";

import {Link} from "gatsby";
import KaisakuApi from "../controllers/kaisaku-api";
export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            maxCount: 0,
            saleCount: 0,
            mode: '',
            message: ""
        };
        let { userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL, mode} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        this.state.mode = mode;
    }

    async createTransactionForM1() {
        console.log("创建订单");

        const res = await KaisakuApi.createTransactionForM1(this.state.accessToken);
        if (res.status === 200){
            const data = res.data;
            navigate(`/xr-saleing-page3${this.props.location.search}&transactionId=${data.transactionId}&buyId=${data.buyId}`);
            this.setState({
                message:""
            })
        }else{
            this.setState({
                message:"購買失敗"
            })
            // alert("已售罄");
        }

    }


    async componentDidMount(){

    }



    render() {
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                        <div style={{height:'32px',textAlign:'left',fontSize:'14px',fontWeight:'bold',
                            paddingTop:'8px',borderLeft:'1px solid #444',borderTop:'1px solid #444',borderRight:'1px solid #444',
                            borderRadius:'10px 10px 0px 0px',
                            backgroundColor:"#262626",
                            color:"#fff",
                            opacity:'1'}}
                        >
                            <div style={{color:'#FFF',float:"left", textAlign:"left",paddingLeft:"10px"}}
                                 onClick={() => {
                                     navigate(`/xr-saleing-page1${this.props.location.search}`);
                                 }}
                            >返回</div>
                            <div style={{color:'red',float:"left", textAlign:"center",paddingLeft:"10px",width:"60%"}}>{this.state.message}</div>
                            {(!this.state.mode) && (<div style={{color:'#FFF',float:"right", textAlign:"left",paddingRight:"10px"}}
                                 onClick={() => {
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                 }}
                            >回到遊戲</div>)}
                        </div>
                        <div style={{
                            width: '289px',
                            borderRadius: '0px 0px 0px 0px',
                            backgroundColor:"#262626",
                            borderLeft:'1px solid #444',borderRight:'1px solid #444',
                            display: ''
                        }}>                            
                            <div style={{width:'289px',minHeight:"50px", maxHeight:"400px", paddingTop:"10px", borderRadius:'10px 10px 0px 0px'}}    
                            onClick={async () => {
                                await this.createTransactionForM1();
                            }}                             
                            >
                                <Image style={{width:"92%"}} centered src={btn2}/>
                                <div style={{color:"#FFF", fontSize:'14px',fontWeight:'bold',padding:"5px"}}>商品詳情：</div>
                            </div>
                            <div style={{width:'289px',height:"60vh",overflowY:"auto", borderRadius:'0px 0px 10px 10px'}}
                                 onClick={async () => {
                                     await this.createTransactionForM1();
                                 }}
                            >
                                <Image style={{}} centered src={title}/>
                                <Image style={{}} centered src={g0}/>
                                <Image style={{}} centered src={g1}/>
                                <Image style={{}} centered src={g2}/>
                                <Image style={{}} centered src={g3}/>
                                <Image style={{}} centered src={g4}/>
                                <Image style={{}} centered src={g5}/>
                                {/* <Image style={{}} centered src={g6}/>
                                <Image style={{}} centered src={g7}/> */}
                            </div>

                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});